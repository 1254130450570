import type {HTMLAttributes} from 'react'
import {Image} from '@elanco/component-library-v2'
import {cloneElement, isValidElement} from 'react'

interface AuxiliaryProps {
	children: React.ReactNode[]
	imageOverflowPosition?: 'top' | 'bottom' | ''
	className?: string
	isLarge?: boolean
	reversed?: boolean
	hideImageOnMobile?: boolean
	imageFull?: boolean
	metaBlock?: React.ReactElement<
		HTMLAttributes<HTMLElement> & {aligned?: 'left' | 'right'}
	> | null
	subtitle?: string
	largeBackgroundButContained?: boolean
}

export const Auxiliary = ({
	className = '',
	children,
	imageOverflowPosition = '',
	isLarge = false,
	reversed = false,
	metaBlock = null,
	hideImageOnMobile = false,
	imageFull = false,
	largeBackgroundButContained = false,
	subtitle = '',
}: AuxiliaryProps): JSX.Element => {
	const title = children.find(
		(child) =>
			isValidElement(child) &&
			typeof child.type === 'string' &&
			child.type.includes('h')
	)
	const infoSection = children.filter(
		(child) =>
			Array.isArray(child) ||
			(child &&
				isValidElement(child) &&
				child.type !== Image &&
				child !== title)
	)

	const image = children.find(
		(child) => child && isValidElement(child) && child.type === Image
	)
	const imgPosition = imageOverflowPosition
	const imgMargin = {
		top: '-mt-24',
		bottom: '-mb-24',
	}
	const rootMargin = {
		top: 'md:mt-12',
		bottom: 'md:mb-12',
	}
	const imgDivClass = {
		top: 'self-start',
		bottom: 'self-end',
	}

	return (
		<div className={largeBackgroundButContained ? 'bg-gray-100' : ''}>
			<div
				className={`${
					largeBackgroundButContained ? '' : 'bg-gray-100'
				} relative mx-auto px-6 py-8 md:flex ${className}
        ${imageFull ? '' : 'px-6 py-8 md:px-6 md:py-10'}
        ${reversed ? 'flex-row-reverse' : ''}
        ${isLarge ? '' : 'container-wide'}
        ${imgPosition ? rootMargin[imgPosition] : ''}`}
			>
				<div
					className={`${image ? 'md:w-2/3' : ''} ${
						imageFull ? 'px-6 py-6' : ''
					} md:mx-4`}
				>
					{subtitle ? (
						<div className="content content--no-width-restriction pb-2 text-sm italic">
							{subtitle}
						</div>
					) : null}
					{title}
					{!hideImageOnMobile && (
						<div
							className={`${
								image ? 'mt-6 ' : ''
							} relative md:hidden`}
						>
							{metaBlock
								? cloneElement(metaBlock, {
										className: `${
											metaBlock.props.className !==
											undefined
												? metaBlock.props.className
												: ''
										} font-bold absolute top-0 ${
											reversed ? 'left-0' : 'right-0'
										}`,
										aligned: reversed ? 'left' : 'right',
									})
								: null}
							{image}
						</div>
					)}
					<div className="content content--no-width-restriction">
						{infoSection}
					</div>
				</div>

				<div
					className={`hidden md:block ${
						imgPosition ? imgDivClass[imgPosition] : ''
					} ${image && !imageFull ? 'md:mx-4 md:w-1/3' : 'md:w-1/3'}`}
				>
					{metaBlock
						? cloneElement(metaBlock, {
								className: `${
									metaBlock.props.className !== undefined
										? metaBlock.props.className
										: ''
								} font-bold absolute top-0 ${
									reversed ? 'left-0' : 'right-0'
								}`,
								aligned: reversed ? 'left' : 'right',
							})
						: null}
					<div className={imgPosition ? imgMargin[imgPosition] : ''}>
						{image}
					</div>
				</div>
			</div>
		</div>
	)
}
