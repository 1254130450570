import {useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import Link from 'next/link'
import {Button, Icon as CIcon} from '@elanco/component-library-v2'
import {useRouter} from 'next/router'
import Image from 'next/future/image'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {fetchPetProfiles} from '@/fetchers/fetchPetProfiles'
import type {Reminder} from '@/fetchers/fetchReminders'
import {fetchPetReminders} from '@/fetchers/fetchReminders'
import type {CtaButtonContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/cta-button'
import type {LinkContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/link'
import {RefillButton} from '@/_new-code/products/your-pet-and-you/blocks/dashboard-page/module-my-reminders/refill-button'
import type {Pet} from '@/models/consumerEngagement/models'
import {pushToDataLayer} from '@/utils/analytics'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {IconContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/icon'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {getMonthlyReminders} from '@/_new-code/products/your-pet-and-you/blocks/dashboard-page/module-my-reminders/get-monthly-reminders'
import {addLocalePrefix} from '@/components/BlockMapper/utils'
import type {AddButtonIconContentItem} from '../../add-button-icon'
import {AddButtonIconBlock} from '../../add-button-icon'
import {CalendarView} from './calendar-view'

type ReminderObjectWithDateKey = Record<string, Reminder[]>

export type ModuleMyRemindersContentItem = IContentItem<{
	title: Elements.TextElement
	addReminderPetButtonIcon: Elements.LinkedItemsElement<AddButtonIconContentItem>
	calendarHeading: Elements.TextElement
	reminderHeading: Elements.TextElement
	editIcon: Elements.LinkedItemsElement<IconContentItem>
	reminderForText: Elements.TextElement
	doseText: Elements.TextElement
	getRemindedHeading: Elements.TextElement
	getRemindedText: Elements.TextElement
	createReminderHeading: Elements.TextElement
	createReminderText: Elements.TextElement
	addAPetButton: Elements.LinkedItemsElement<CtaButtonContentItem>
	addAReminderButton: Elements.LinkedItemsElement<CtaButtonContentItem>
	petsDropdownText: Elements.TextElement
	viewAllRemindersLink: Elements.LinkedItemsElement<LinkContentItem>
}>

export const ModuleMyRemindersBlock: Block<ModuleMyRemindersContentItem> = ({
	block,
	globalConfig,
	...context
}) => {
	const {locale: pageLocale = ''} = useRouter()
	const {
		title,
		calendarHeading,
		reminderHeading,
		editIcon,
		reminderForText,
		doseText,
		getRemindedHeading,
		getRemindedText,
		createReminderHeading,
		createReminderText,
		addAPetButton,
		addAReminderButton,
		petsDropdownText,
		viewAllRemindersLink,
		addReminderPetButtonIcon,
	} = block.elements

	const {isLoading, data} = useQuery<Reminder[], Error>({
		queryKey: ['reminders'],
		queryFn: () => fetchPetReminders(pageLocale),
		staleTime: Infinity,
	})
	const {isLoading: petsIsLoading, data: pets} = useQuery<Pet[], Error>({
		queryKey: ['pets'],
		queryFn: () => fetchPetProfiles(pageLocale),
		staleTime: Infinity,
		cacheTime: 15 * (60 * 1000),
	})
	const [activePet, setActivePet] = useState<string>('')
	const [remindersData, setRemindersData] =
		useState<ReminderObjectWithDateKey>({})
	const router = useRouter()

	const convertToLongDate = (date: string): string =>
		new Date(date).toLocaleDateString('en-US', {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
		})
	useEffect(() => {
		if (!data) return

		let reminders = data
		if (activePet) reminders = data.filter(({pet}) => pet?.id === activePet)

		const monthlyReminders = getMonthlyReminders(reminders)
		const currentDate = new Date()
		const currentDatePlus30Days = new Date(
			currentDate.setDate(currentDate.getDate() + 30)
		)
		const remindersWithin30Days = monthlyReminders.filter(
			(d) =>
				d.recurringDates?.[0] &&
				new Date(d.recurringDates[0]) < currentDatePlus30Days
		)

		remindersWithin30Days.sort((a, b) => {
			const dateA = a.recurringDates?.[0]?.split('/') ?? []
			const dateB = b.recurringDates?.[0]?.split('/') ?? []

			return (
				// @ts-expect-error -- TODO: fix this
				dateA[2] - dateB[2] ||
				// @ts-expect-error -- TODO: fix this
				dateA[0] - dateB[0] ||
				// @ts-expect-error -- TODO: fix this
				dateA[1] - dateB[1]
			)
		})
		const sortDataIntoObjectWithDateKey = remindersWithin30Days.reduce(
			(acc: ReminderObjectWithDateKey, cur) => {
				if (!cur.recurringDates?.[0]) return acc

				const longDate = convertToLongDate(cur.recurringDates[0])
				acc[longDate] = [...(acc[longDate] || []), cur]
				return acc
			},
			{}
		)

		setRemindersData(sortDataIntoObjectWithDateKey)
	}, [data, activePet])

	return (
		<div className="container-wide my-8 flex flex-col">
			<h2 className="mb-2 text-2xl font-semibold text-midnight-blue">
				{title}
			</h2>
			<div className="mb-3 flex flex-col md:flex-row md:justify-between">
				<div className="my-2 flex flex-col justify-between gap-2">
					<div className="relative md:w-[200px]">
						<select
							className="mb-0 w-full appearance-none rounded border border-pet-midnight !bg-white py-1 pl-4 pr-8 text-sm font-semibold text-pet-primary md:w-[200px]"
							disabled={petsIsLoading}
							name="pets-dropdown"
							onChange={(e) => {
								setActivePet(e.target.value)
							}}
							value={activePet}
						>
							<option value="">{petsDropdownText}</option>
							{(pets ?? []).map((pet) => (
								<option key={pet.id} value={pet.id}>
									{pet.name}
								</option>
							))}
						</select>
						<svg
							aria-hidden="true"
							className="mr-[7px] mt-[5px] !fill-pet-midnight"
							focusable="false"
							height="20"
							viewBox="0 0 20 20"
							width="20"
						>
							<path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" />
						</svg>
					</div>
				</div>
				{addReminderPetButtonIcon.length > 0 ? (
					<div className="order-first mb-3 flex justify-start space-x-4 md:order-last md:items-end">
						{addReminderPetButtonIcon.map((buttonIcon) => (
							<AddButtonIconBlock
								block={buttonIcon}
								globalConfig={globalConfig}
								key={buttonIcon.system.id}
								{...context}
							/>
						))}
					</div>
				) : null}
			</div>
			<div className="relative flex flex-col md:flex-row">
				<CalendarView
					activePet={activePet}
					defaultDate={
						calendarHeading === 'storybook-custom-heading'
							? new Date('2024-09-09')
							: new Date()
					}
					heading={calendarHeading}
					reminders={data ?? []}
				/>
				<div className="flex-1 rounded rounded-t-none border border-t-0 border-pet-midnight bg-[#EBEBEB]/50 pl-8 pr-8 md:rounded-l-none md:rounded-tr md:border-l-0 md:border-t">
					<h4 className="pb-4 pt-4 text-left font-semibold text-pet-midnight">
						{reminderHeading}
					</h4>
					{!isLoading && !petsIsLoading ? (
						<div className="h-[420px] overflow-y-scroll">
							{Object.entries(remindersData).map(
								([date, reminder]: [string, Reminder[]]) => (
									<div
										className="mb-6 flex flex-col rounded bg-white p-6"
										key={date}
									>
										<span className="font-semibold text-pet-midnight">
											{date}
										</span>
										{reminder.map((r) => (
											<div
												className="mb-4 mt-2 flex flex-col odd:border-t odd:pt-4"
												key={r.id}
											>
												<div className="flex">
													<div className="flex basis-10/12 flex-col md:basis-6/12">
														<span className="text-md">
															{reminderForText.replace(
																'{{petName}}',
																r.pet?.name ??
																	''
															)}
														</span>
														<span className="text-xs italic">
															{doseText.replace(
																'{{petName}}',
																r.pet?.name ??
																	''
															)}
															<span className="font-semibold">
																{' '}
																{
																	r
																		.marketingBrand
																		?.name
																}
															</span>
														</span>
													</div>
													<div className="flex basis-2/12 items-center justify-end gap-4 md:basis-6/12">
														<CIcon
															className="h-6 w-6"
															noBackground
															noBorder
														>
															<button
																className="flex justify-center"
																onClick={() => {
																	void router.push(
																		{
																			pathname: `/${pageLocale}${router.asPath}`,
																			query: {
																				edit: true,
																				reminder:
																					r.id,
																			},
																		},
																		undefined,
																		{
																			shallow:
																				true,
																		}
																	)
																}}
																type="button"
															>
																{editIcon[0]
																	?.elements
																	.icon[0] ? (
																	<Image
																		alt={
																			editIcon[0]
																				.elements
																				.icon[0]
																				.description ??
																			''
																		}
																		className="h-6 w-6"
																		height={
																			24
																		}
																		loader={
																			loadImageFromKontentAI
																		}
																		src={
																			editIcon[0]
																				.elements
																				.icon[0]
																				.url
																		}
																		width={
																			24
																		}
																	/>
																) : null}
															</button>
														</CIcon>
														<div className="hidden md:block">
															<RefillButton
																className="m-2 text-sm uppercase"
																globalConfig={
																	globalConfig
																}
																id={
																	r
																		.marketingBrand
																		?.id
																}
															/>
														</div>
													</div>
												</div>
												<div className="mt-4 block md:hidden">
													<RefillButton
														className="text-sm uppercase"
														globalConfig={
															globalConfig
														}
														id={
															r.marketingBrand?.id
														}
													/>
												</div>
											</div>
										))}
									</div>
								)
							)}
							{Object.keys(remindersData).length === 0 &&
								pets?.length === 0 && (
									<div className="mb-6 flex flex-col rounded bg-white p-6 md:flex-row">
										<div className="flex basis-6/12 flex-col md:justify-center">
											<span className="font-semibold text-pet-midnight">
												{getRemindedHeading}
											</span>
											<span className="text-xs">
												{getRemindedText}
											</span>
										</div>
										{addAPetButton.length ? (
											<div className="flex basis-6/12 items-center gap-4 md:justify-end">
												{addAPetButton[0] ? (
													<Button
														className="mt-4 text-sm uppercase md:m-2"
														href={addLocalePrefix(
															addAPetButton[0]
																.elements.url,
															pageLocale
														)}
														onClick={() => {
															pushToDataLayer({
																event: 'cta_click',
																cta_name:
																	addAPetButton[0]
																		?.elements
																		.text,
																cta_category:
																	addAPetButton[0]
																		?.system
																		.type,
															})
														}}
														type="button"
														variant="pet_primary_green"
													>
														{
															addAPetButton[0]
																.elements.text
														}
													</Button>
												) : null}
											</div>
										) : null}
									</div>
								)}
							{Object.keys(remindersData).length === 0 &&
								pets?.length !== 0 && (
									<div className="mb-6 flex flex-col rounded bg-white p-6 md:flex-row">
										<div className="flex basis-6/12 flex-col md:justify-center">
											<span className="font-semibold text-pet-midnight">
												{createReminderHeading}
											</span>
											<span className="text-xs">
												{createReminderText}
											</span>
										</div>
										{addAReminderButton.length ? (
											<div className="flex basis-6/12 items-center gap-4 md:justify-end">
												{addAReminderButton[0] ? (
													<Button
														className="mt-4 text-sm uppercase md:m-2"
														onClick={() => {
															pushToDataLayer({
																event: 'cta_click',
																cta_name:
																	addAReminderButton[0]
																		?.elements
																		.text,
																cta_category:
																	addAReminderButton[0]
																		?.system
																		.type,
															})
															void router.push(
																{
																	pathname:
																		addLocalePrefix(
																			router.asPath,
																			pageLocale
																		),
																	query: {
																		reminder:
																			'',
																	},
																},
																undefined,
																{
																	shallow:
																		true,
																}
															)
														}}
														type="button"
														variant="pet_primary_green"
													>
														{
															addAReminderButton[0]
																.elements.text
														}
													</Button>
												) : null}
											</div>
										) : null}
									</div>
								)}
						</div>
					) : (
						<>
							{Array(2)
								.fill(1)
								.map((_, idx) => (
									<div
										className="mb-6 w-full animate-pulse rounded bg-white p-6"
										// eslint-disable-next-line react/no-array-index-key -- No other alternative
										key={`module-my-reminders-${idx}`}
									>
										<div className="mb-4 h-6 w-6/12 animate-pulse rounded bg-gray-200" />
										<div className="mb-4 h-14 w-full animate-pulse rounded bg-gray-200" />
										<span
											aria-busy="true"
											aria-live="polite"
											className="sr-only"
											role="alert"
										>
											Loading...
										</span>
									</div>
								))}
						</>
					)}
				</div>
			</div>
			{viewAllRemindersLink.map((link) => (
				<Link
					href={addLocalePrefix(link.elements.url, pageLocale)}
					key={link.system.id}
					passHref
					rel="noreferrer"
				>
					{/* eslint-disable-next-line jsx-a11y/anchor-is-valid -- TODO: remove when next/link updates */}
					<a
						className="mt-2 text-sm uppercase text-green-600"
						rel="noreferrer"
						target={
							link.elements.openInNewTab[0]?.codename === 'yes'
								? '_blank'
								: '_self'
						}
					>
						{link.elements.text}
						<span className="ml-1 text-xl">&rsaquo;</span>
					</a>
				</Link>
			))}
		</div>
	)
}
