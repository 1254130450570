import {Icon as CIcon, HtmlTextWithStyling} from '@elanco/component-library-v2'
import Image from 'next/future/image'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {clsx} from 'clsx'
import {customBrandChoices, customBrands} from '@/components/BlockMapper/utils'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {IconWithTextContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/icon-with-text'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {IconsSection} from '../../components/icons-section'
import type {CtaButtonContentItem} from '../cta-button'
import {ReusableCTAButtonComponent} from '../cta-button'
import {spacing} from '../../styles'

export type SmallBenefitsModuleContentItem = IContentItem<{
	iconsWithText: Elements.LinkedItemsElement<IconWithTextContentItem>
	ctaButton: Elements.LinkedItemsElement<CtaButtonContentItem>
	borderOption: Elements.MultipleChoiceElement
	squareIcon: Elements.MultipleChoiceElement
	snippetSpacingSpacing: Elements.MultipleChoiceElement
}>

export const SmallBenefitsModuleBlock: Block<
	SmallBenefitsModuleContentItem
> = ({block, ...context}) => {
	const componentSpacing =
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- not unnecessary, spacingChoice can be undefined
		spacing[
			block.elements.snippetSpacingSpacing[0]
				?.codename as keyof typeof spacing
		] || spacing.none

	return (
		<div className={`container-wide ${componentSpacing}`}>
			<IconsSection.Icons col={4}>
				{block.elements.iconsWithText.map(
					({
						elements: {
							icon,
							brand,
							iconSize,
							title,
							boldTitle,
							description,
						},
						system: {id},
					}) => (
						<div data-kontent-item-id={id} key={id}>
							<CIcon
								brand={
									brand[0]?.codename &&
									customBrandChoices.includes(
										brand[0].codename
									)
										? 'none'
										: brand[0]?.codename || 'none'
								}
								iconCustomClasses={clsx(
									'p-1',
									brand[0]?.codename &&
										customBrandChoices.includes(
											brand[0].codename
										)
										? customBrands[brand[0]?.codename]
												?.border
										: ''
								)}
								noBorder={
									!(
										block.elements.borderOption[0]
											?.codename === 'yes'
									)
								}
								showSquareIcon={
									block.elements.squareIcon[0]?.codename ===
									'yes'
								}
								size={iconSize[0]?.codename || 'lg'}
								title={
									title ? (
										<span
											className={
												boldTitle[0]?.codename ===
												'bold'
													? 'font-bold'
													: ''
											}
											data-kontent-element-codename="title"
										>
											{title}
										</span>
									) : null
								}
							>
								<div className="relative h-full w-full object-contain">
									<Image
										alt={icon[0]?.description ?? ''}
										data-kontent-element-codename="icon"
										height={icon[0]?.height || 200}
										loader={loadImageFromKontentAI}
										src={
											icon[0]?.url ??
											'https://assets-eu-01.kc-usercontent.com/43db687c-fb47-015f-c990-00802940e2bc/f03522f9-0615-4ca8-a83c-3eb311fd2159/placeholder-256x176.png'
										}
										width={icon[0]?.width || 200}
									/>
								</div>
							</CIcon>
							{description.value ? (
								<HtmlTextWithStyling
									className="content content--no-width-restriction leading-tight"
									data-kontent-element-codename="description"
									text={description.value}
								/>
							) : null}
						</div>
					)
				)}
			</IconsSection.Icons>
			{block.elements.ctaButton.map((button) => (
				<div className="flex justify-center" key={button.system.id}>
					<ReusableCTAButtonComponent
						block={button}
						className="mt-6"
						{...context}
					/>
				</div>
			))}
		</div>
	)
}
