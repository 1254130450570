import type {PropsWithChildren, ReactNode} from 'react'
import {useState, useEffect} from 'react'
import Sticky from 'react-stickynode'
import {useRouter} from 'next/router'
import type {
	Block,
	BrandConfigContentItem,
	Tersed,
} from '@/_new-code/services/kontent-ai/types'
import {HeaderBreadcrumbs} from '@/_new-code/products/flexible-web-toolkit/components/header/breadcrumbs'
import {NotificationBannerBlock} from '@/_new-code/products/flexible-web-toolkit/components/header/notification-banner'
import {useWindowSize} from '@/_new-code/utilities/hooks/use-window-size'
import {SearchTogglePopup} from '../search-toggle-popup'
import {OnPageNavBlock} from './on-page-navigation'
import type {HeaderState} from './helpers'
import {PrimaryNavigation} from './primary'

const HeaderContainer = ({
	children,
	isSearchModalOpen,
	searchModal,
	hId,
}: PropsWithChildren<{
	isSearchModalOpen: boolean
	searchModal: () => ReactNode
	hId: string
}>): JSX.Element => {
	return (
		<div>
			{isSearchModalOpen ? searchModal() : null}
			<header data-kontent-item-id={hId}>{children}</header>
		</div>
	)
}

export const HeaderBlock: Block = ({
	globalConfig,
	page,
	...context
}): JSX.Element => {
	const globalHeader = globalConfig.elements.headerConfig[0]
	const pageHeader = page.elements.headerConfig[0]
	const headerConfig = pageHeader ?? globalHeader

	const size = useWindowSize()
	const [headerState, setHeaderState] = useState<HeaderState>({
		navFixed: false,
		navVisible: false,
	})
	const [shouldApplyPadding, setShouldApplyPadding] = useState(true)

	// const authState = useAuth()
	const {locale = ''} = useRouter()

	const onStateChange = (e: Sticky.Status): void => {
		setHeaderState((currentState: HeaderState) => ({
			...currentState,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison -- using an enum comparison crashes
			navFixed: e.status > 0,
		}))
	}

	useEffect(() => {
		// Find the element with both 'h-full' and 'mt-12' classes
		const fullHeightElementWithMargin =
			document.querySelector('.h-full.mt-12')

		// If the element exists, don't apply the padding, since mt-12 is used in sectionheader
		if (fullHeightElementWithMargin) {
			setShouldApplyPadding(false)
		} else {
			setShouldApplyPadding(true)
		}
	}, [])

	if (!headerConfig) return <></>

	const pageConfig = page.elements.config[0]

	const globalSecondaryHeaderConfig =
		headerConfig.elements.secondaryHeaderConfig[0]

	const pageSecondaryHeaderConfig =
		pageConfig && 'secondaryConfig' in pageConfig.elements
			? (pageConfig as Tersed<BrandConfigContentItem>).elements
					.secondaryConfig[0]
			: null

	const secondaryHeaderConfig =
		pageSecondaryHeaderConfig ?? globalSecondaryHeaderConfig

	const color =
		pageConfig && 'color' in pageConfig.elements
			? (pageConfig as Tersed<BrandConfigContentItem>).elements.color[0]
					?.codename
			: null

	return (
		<SearchTogglePopup>
			{(togglePopup: () => void, isSearchModalOpen: boolean) => {
				const {isMobile} = size

				const renderPrimaryNavigation = (): JSX.Element => (
					<PrimaryNavigation
						globalConfig={globalConfig}
						headerState={headerState}
						page={page}
						setHeaderState={setHeaderState}
						toggleSearchPopup={togglePopup}
					/>
				)

				const renderBreadcrumbs = (): JSX.Element => (
					<>
						{globalConfig.elements.showBreadcrumbs[0]?.codename ===
							'show' &&
							page.elements.snippetPageSeoHideBreadcrumbs[0]
								?.codename !== 'hide' && (
								<HeaderBreadcrumbs page={page} />
							)}
					</>
				)

				const getSearchModal = (): JSX.Element => (
					<SearchTogglePopup.Modal
						action={`/${locale}${
							headerConfig.elements.searchUrl || '/search'
						}`}
						closeBtnText={headerConfig.elements.searchCloseText}
						searchPlaceholderText={
							headerConfig.elements.searchPlaceholder
						}
						togglePopup={togglePopup}
					/>
				)

				if (
					headerState.navVisible &&
					secondaryHeaderConfig &&
					isMobile
				) {
					return (
						<HeaderContainer
							hId={headerConfig.system.id}
							isSearchModalOpen={isSearchModalOpen}
							searchModal={getSearchModal}
						>
							<Sticky
								innerZ={99}
								onStateChange={onStateChange}
								top={0}
							>
								<div id="sticky-nav">
									{renderPrimaryNavigation()}
									<NotificationBannerBlock
										globalConfig={globalConfig}
										page={page}
										position="top"
										{...context}
									/>
								</div>
							</Sticky>
							{renderBreadcrumbs()}
							<OnPageNavBlock
								{...context}
								block={{
									elements: {
										authenticationGateComponentUserRoles:
											[],
									},
									system: secondaryHeaderConfig.system,
								}}
								className="product-navigation"
								globalConfig={globalConfig}
								page={page}
								pageConfigColor={color ?? undefined}
								secondaryHeaderConfig={secondaryHeaderConfig}
							/>
						</HeaderContainer>
					)
				}

				if (secondaryHeaderConfig) {
					return (
						<HeaderContainer
							hId={headerConfig.system.id}
							isSearchModalOpen={isSearchModalOpen}
							searchModal={getSearchModal}
						>
							<div className="h-auto">
								{renderPrimaryNavigation()}
								{renderBreadcrumbs()}
							</div>
							<Sticky
								innerZ={99}
								onStateChange={onStateChange}
								top={0}
							>
								<div
									className={
										shouldApplyPadding
											? 'pb-20 md:pb-0'
											: 'md:pb-0'
									}
									id="sticky-nav"
								>
									<NotificationBannerBlock
										globalConfig={globalConfig}
										page={page}
										position="top"
										{...context}
									/>
									<OnPageNavBlock
										{...context}
										className="product-navigation !w-full"
										globalConfig={globalConfig}
										page={page}
										pageConfigColor={color ?? undefined}
										secondaryHeaderConfig={
											secondaryHeaderConfig
										}
									/>
								</div>
							</Sticky>
						</HeaderContainer>
					)
				}

				return (
					<HeaderContainer
						hId={headerConfig.system.id}
						isSearchModalOpen={isSearchModalOpen}
						searchModal={getSearchModal}
					>
						<Sticky innerZ={99} top={0}>
							<div id="sticky-nav">
								{renderPrimaryNavigation()}
								<NotificationBannerBlock
									globalConfig={globalConfig}
									page={page}
									position="top"
									{...context}
								/>
							</div>
						</Sticky>
						{renderBreadcrumbs()}
					</HeaderContainer>
				)
			}}
		</SearchTogglePopup>
	)
}
