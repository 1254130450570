import {useWindowSize} from '@/_new-code/utilities/hooks/use-window-size'

interface AnchorProps {
	anchorLink?: string
	classNames?: string
	scrollMarginTop?: {
		desktop: string
		mobile: string
	}
}

export const Anchor = ({
	anchorLink = '',
	classNames = '',
	scrollMarginTop = {
		desktop: '8rem',
		mobile: '4rem',
	},
}: AnchorProps): JSX.Element => {
	const size = useWindowSize()
	const {desktop, mobile} = scrollMarginTop

	return (
		<div
			className={`invisible h-0 w-0 ${classNames}`}
			id={anchorLink.trim().replace(/\s+/g, '-').toLowerCase()}
			style={{scrollMarginTop: size.isMobile ? mobile : desktop}}
		/>
	)
}
